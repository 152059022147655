import './Piece.css';
import { useParams } from 'react-router-dom';
const images = require.context('../images', true);

function Piece() {
    const imageList = [
        ['1.png', '2.png', '3.png'],
        ['1.png', '2.png', '3.png', '4.png', '5.jpg', '6.jpg'],
        ['1.png', '2.png'],
        ['1.png', '2.png', '3.png'],
        ['1.png', '2.png', '4.png', '5.png', '6.png', '7.png', '8.png', '9.png', '10.jpg', '11.jpg'],
        ['1.jpg', '2.jpg', '3.jpg'],
        ['1.png', '2.png', '3.png', '4.png'],
        ['1.png'],
    ]
    const descriptionList = [
        {
            name: 'Cocktail Table',
            dimensions:'Dimensions: 98cm x 79cm x 42cm',
            material: 'Material: ceramics',
            status: 'Production ongoing',
        },
        {
            name: 'Common Form no.1',
            dimensions:'Dimensions: 44cm x 20cm x 20cm',
            material: 'Material: polystyrene, clay',
            status: 'Available pieces',
        },
        {
            name: 'Credenza',
            dimensions:'Dimensions: 123cm x 38cm x 43cm (78cm)',
            material: 'Material: glass',
            status: 'Production ongoing',
        },
        {
            name: 'Dining Table',
            dimensions:'Dimensions: 120cm x 120cm x 76cm',
            material: 'Material: marble',
            status: 'Production ongoing',
        },
        {
            name: 'Side Table Eccentric',
            dimensions:'Dimensions: 60cm x 60cm x 60cm',
            material: 'Material: walnut, ash, oak',
            status: 'Available pieces',
        },
        {
            name: 'Coffee Table Inconcrete',
            dimensions:'Dimensions: 46cm x 42cm x 44cm',
            material: 'Material: concrete',
            status: 'Available pieces',
        },
        {
            name: 'Side Table',
            dimensions:'Dimensions: 65cm x 40cm x 56cm',
            material: 'Material: ceramics',
            status: 'Production ongoing',
        },
        {
            name: 'Side Table',
            dimensions:'Dimensions: 40cm x 30cm x 60cm',
            material: 'Material: ceramics',
            status: 'Production ongoing',
        },
    ];
    const {pieceId} = useParams();
    const imageArray = imageList[pieceId-1];
    return (
    <div className="piece-details">
       <div className="piece-details-images-container">
           {
               imageArray.map(image =>(
                   <div className='piece-details-image'>
                       <img src={images(`./${pieceId}/${image}`)} alt={image} key={image}/>
                   </div>
               ))
           }
       </div>
        <p className="piece-details-description">
            {descriptionList[pieceId-1].name}<br/>
            {descriptionList[pieceId-1].dimensions}<br/>
            {descriptionList[pieceId-1].material}<br/>
            {descriptionList[pieceId-1].status}<br/>
        </p>
    </div>
    );
}

export default Piece;