import './Footer.css';

function Footer() {
    return (
        <div className="footer-inforamtion-container" id="info">
            <div className="footer-inforamtion-content">
                <div>CONTACT</div>
                <div>info@hyperspace.rs</div>
                <div>+381 62 543 090</div>
                <div>Zemunska 154</div>
                <div>11272 Belgrade</div>
                <div>Serbia</div>
            </div>
            <div className="footer-inforamtion-content">
                <div>SHIPPING</div>
                <div>Shipping rates will automatically be calculated and added at checkout.
                    You will receive an email notification once your order has been dispatched
                    (typically 2-4 days).
                </div>
            </div>
        </div>
    );
}

export default Footer;
