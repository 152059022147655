import './Pieces.css';
import { Link } from "react-router-dom";
const images = require.context('../images', true);

function Information() {
    const piecesList = [
        [{
            id: 1,
            image: '1.png',
            description: {
                name: 'Cocktail Table',
                dimensions:'Dimensions: 98cm x 79cm x 42cm',
                material: 'Material: ceramics',
                status: 'Production ongoing',
            },
        },
        {
            id: 4,
            image: '1.png',
            description: {
                name: 'Dining Table',
                dimensions:'Dimensions: 120cm x 120cm x 76cm',
                material: 'Material: marble',
                status: 'Production ongoing',
            },
        },
        {
            id: 7,
            image: '1.png',
            description: {
                name: 'Side Table',
                dimensions:'Dimensions: 65cm x 40cm x 56cm',
                material: 'Material: ceramics',
                status: 'Production ongoing',
            },
        },],
        [{
            id: 2,
            image: '1.png',
            description: {
                name: 'Common Form no.1',
                dimensions:'Dimensions: 44cm x 20cm x 20cm',
                material: 'Material: polystyrene, clay',
                status: 'Available pieces',
            },
        },
        {
            id: 5,
            image: '1.png',
            description: {
                name: 'Side Table Eccentric',
                dimensions:'Dimensions: 60cm x 60cm x 60cm',
                material: 'Material: walnut, ash, oak',
                status: 'Available pieces',
            },
        },
        {
            id: 8,
            image: '1.png',
            description: {
                name: 'Side Table',
                dimensions:'Dimensions: 40cm x 30cm x 60cm',
                material: 'Material: ceramics',
                status: 'Production ongoing',
            },
        }],
        [{
            id: 3,
            image: '1.png',
            description: {
                name: 'Credenza',
                dimensions:'Dimensions: 123cm x 38cm x 43cm (78cm)',
                material: 'Material: glass',
                status: 'Production ongoing',
            },
        },
        {
            id: 6,
            image: '1.jpg',
            description: {
                name: 'Coffee Table Inconcrete',
                dimensions:'Dimensions: 46cm x 42cm x 44cm',
                material: 'Material: concrete',
                status: 'Available pieces',
            },
        }],
    ];
    return (
        <div className="pieces-container">
            <div className="pieces-column" style={{marginTop: "30px"}}>
                {piecesList[0].map(piece => (
                    <Link className="piece-container" key={piece.id} to={`/pieces/${piece.id}`}>
                        <img src={images(`./${piece.id}/${piece.image}`)} alt={piece.image} key={`${piece.id}-${piece.image}`}/>
                        <div className="piece-description">
                            {piece.description.name}<br/>
                            {piece.description.dimensions}<br/>
                            {piece.description.material}<br/>
                            {piece.description.status}<br/>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="pieces-column" style={{marginTop: "100px"}}>
                {piecesList[1].map(piece => (
                    <Link className="piece-container" key={piece.id} to={`/pieces/${piece.id}`}>
                        <img src={images(`./${piece.id}/${piece.image}`)} alt={piece.image} key={`${piece.id}-${piece.image}`}/>
                        <div className="piece-description">
                            {piece.description.name}<br/>
                            {piece.description.dimensions}<br/>
                            {piece.description.material}<br/>
                            {piece.description.status}<br/>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="pieces-column" style={{marginTop: "40px"}}>
                {piecesList[2].map(piece => (
                    <Link className="piece-container" key={piece.id} to={`/pieces/${piece.id}`}>
                        <img src={images(`./${piece.id}/${piece.image}`)} alt={piece.image} key={`${piece.id}-${piece.image}`}/>
                        <div className="piece-description">
                            {piece.description.name}<br/>
                            {piece.description.dimensions}<br/>
                            {piece.description.material}<br/>
                            {piece.description.status}<br/>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Information;