import './style.css';
import { Link } from "react-router-dom";

function Navigation() {
    return (
        <div className="navigation-container">
            <div className="title">
                Nikola Lajic
            </div>
            <div className="navigation-button-wrapper">
                <Link className="navigation-button" to={`pieces/`}>
                    Work
                </Link>
                <Link className="navigation-button" to={`info/`}>
                    Info
                </Link>
            </div>
        </div>
    );
}

export default Navigation;